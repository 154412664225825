import React, { useEffect } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { usePrismicPreview } from 'gatsby-source-prismic';

const Preview = ({ location }) => {
  const {
    allPrismicArticle,
    allPrismicPage,
    allPrismicProjectCategory,
    allPrismicProject,
    allPrismicServiceCategory,
    allPrismicService,
    site,
  } = useStaticQuery(query);

  const articleUIDs = allPrismicArticle.nodes.map((node) => node.uid);
  const pageUIDs = allPrismicPage.nodes.map((node) => node.uid);
  const projectCategoryUids = allPrismicProjectCategory.nodes.map((node) => node.uid);
  const projectUids = allPrismicProject.nodes.map((node) => node.uid);
  const serviceCategoryUids = allPrismicServiceCategory.nodes.map((node) => node.uid);
  const serviceUids = allPrismicService.nodes.map((node) => node.uid);

  const {
    siteMetadata: { prismicRepo },
  } = site;

  const pathResolver = () => (doc) => {
    const previewedUID = doc.uid;
    if (previewedUID === 'home') return '/';
    if (articleUIDs.includes(previewedUID)) {
      return `/insights/${previewedUID}`;
    }
    if (pageUIDs.includes(previewedUID)) {
      return `/${previewedUID}`;
    }
    if (projectCategoryUids.includes(previewedUID)) {
      return `/projects/${previewedUID}`;
    }
    if (projectUids.includes(previewedUID)) {
      const activeNode = allPrismicProject.nodes.find((node) => previewedUID === node.uid);
      const category = activeNode && activeNode.category && activeNode.category.uid;
      return `/projects/${category}/${previewedUID}`;
    }
    if (serviceCategoryUids.includes(previewedUID)) {
      return `/${previewedUID}`;
    }
    if (serviceUids.includes(previewedUID)) {
      const activeNode = allPrismicService.nodes.find((node) => previewedUID === node.uid);
      const category = activeNode && activeNode.category && activeNode.category.uid;
      return `/${category}/${previewedUID}`;
    }
    return '/unpublishedPreview';
  };

  const { previewData, path } = usePrismicPreview({
    repositoryName: prismicRepo,
    pathResolver,
  });

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData;
      navigate(path);
    }
  }, [previewData, path]);

  return <div>Loading preview...</div>;
};

export default Preview;

const query = graphql`
  query previewQuery {
    allPrismicArticle {
      nodes {
        uid
      }
    }
    allPrismicPage {
      nodes {
        uid
      }
    }
    allPrismicProjectCategory {
      nodes {
        uid
      }
    }
    allPrismicProject {
      nodes {
        uid
        data {
          category {
            uid
          }
        }
      }
    }
    allPrismicServiceCategory {
      nodes {
        uid
      }
    }
    allPrismicService {
      nodes {
        uid
        data {
          category {
            uid
          }
        }
      }
    }
    site {
      siteMetadata {
        prismicRepo
      }
    }
  }
`;
